import Keycloak from "keycloak-js";
import {CONFIG_KEYCLOAK_REALM} from "@/config";

const KEYCLOAK_CONFIG = {
    url: 'https://auth.innosend.eu/',
    realm: CONFIG_KEYCLOAK_REALM,
    clientId: 'innosend-admin',
};

const KEYCLOAK_INIT_OPTIONS = {
    onLoad: 'check-sso',
    enableLogging: true,
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
};

export const keycloak = new Keycloak(KEYCLOAK_CONFIG);

export async function initKeycloak() {
    await keycloak.init(KEYCLOAK_INIT_OPTIONS);
}
