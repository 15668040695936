export const CONFIG_INVOICE_DUE_PERIOD = 14;
export const CONFIG_KEYCLOAK_ADMIN_URL = 'https://auth.innosend.eu/admin/';

const injectedConfig = window?.config;

/**
 * @typedef {'production' | 'staging' | 'test' | 'development'} EnvType
 */

/** @type {EnvType} */
export const CONFIG_ENV = injectedConfig?.VUE_APP_ENV || process.env.VUE_APP_ENV || 'development';

const getApiSuffix = () => {
  if (CONFIG_ENV === 'staging') return '-staging';
  if (CONFIG_ENV === 'test') return '-test';
  return '';
};

const API_SUFFIX = getApiSuffix();

export const BACKEND_PORT = process.env.VUE_APP_API_PORT || 5000;
export const UVDESK_PORT = process.env.VUE_APP_UVDESK_PORT || 8084;

const isDevelopment = CONFIG_ENV === 'development';

export const CONFIG_API_URL = isDevelopment
  ? `http://127.0.0.1:${BACKEND_PORT}/admin/`
  : `https://api${API_SUFFIX}.innosend.eu/admin/`;

export const CONFIG_UVDESK_URL = isDevelopment
? `http://localhost:${UVDESK_PORT}`
: `https://uvdesk${API_SUFFIX}.innosend.eu`;

// innosend-dev innosend-prod innosend-staging innosend-test
const getRealm = () => {
  switch (CONFIG_ENV) {
    case 'prod':
      return 'innosend-prod';
    case 'staging':
      return 'innosend-staging';
    case 'test':
      return 'innosend-test';
    default:
      return 'innosend-dev';
  }
};

export const CONFIG_KEYCLOAK_REALM = getRealm();
