import { twMerge } from 'tailwind-merge';

const computeClasses = (el, binding, vnode) => {
  const existingClasses = el.classList.value;
  const inheritedClasses = vnode.context.$attrs.class;

  // No need to run twMerge if there are no classes
  if (!existingClasses || !inheritedClasses) return;

  // This works because all fallthrough classes are added at the end of the string
  el.classList.value = twMerge(existingClasses, inheritedClasses);
};

const directive = {
  bind: computeClasses,
  update: computeClasses,
};

// the actual plugin
export default {
  install: Vue => {
    Vue.directive('twMerge', directive);
  },
};
