<template>
<article class="section">
<div class="container">
    <section class="columns block">
        <aside class="column is-one-fifth-tablet is-2-fullhd">
            <b-menu>
                <b-menu-list label="Accounts">
                    <b-menu-item
                        tag="a"
                        :href="CONFIG_KEYCLOAK_ADMIN_URL"
                        target="_blank"
                    >
                        <template #label>
                            Keycloak admin
                            <span class="icon is-small has-text-grey-light is-pulled-right" title="Externe link">
                                <i class="gg-external" aria-hidden="true"></i>
                                <span class="is-sr-only">Externe link</span>
                            </span>
                        </template>
                    </b-menu-item>
                    <b-menu-item
                        tag="router-link"
                        label="Organisaties"
                        :to="{ name: 'organisation-list' }"
                    ></b-menu-item>
                </b-menu-list>
                <b-menu-list label="Facturatie">
                    <b-menu-item
                        tag="router-link"
                        label="Facturen"
                        :to="{ name: 'invoice-list' }"
                    ></b-menu-item>
                    <b-menu-item
                        tag="router-link"
                        label="Prijzen"
                        :to="{ name: 'price-list' }"
                    ></b-menu-item>
                </b-menu-list>
                <b-menu-list label="Data">
                    <b-menu-item
                        tag="router-link"
                        label="Data explorer"
                        :to="{ name: 'explorer' }"
                    ></b-menu-item>
                    <b-menu-item
                        tag="router-link"
                        label="GDPR"
                        :to="{ name: 'gdpr-list' }"
                    ></b-menu-item>
                </b-menu-list>
                <b-menu-list label="Tickets">
                    <b-menu-item
                        tag="router-link"
                        label="Ticket Pagina"
                        :to="{ name: 'ticket' }"
                    ></b-menu-item>
                </b-menu-list>
                <b-menu-list label="Utils">
                    <b-menu-item
                        tag="router-link"
                        label="Label Dump"
                        :to="{ name: 'label-dump' }"
                    ></b-menu-item>
                    <b-menu-item
                        tag="router-link"
                        label="Log View"
                        :to="{ name: 'log-view' }"
                    ></b-menu-item>
                </b-menu-list>
            </b-menu>
        </aside>
        <main class="column">
            <header class="block">
                <h1 class="title">Innosend Admin</h1>
            </header>
            <subscription-changes-list></subscription-changes-list>
            <recent-organisation-list></recent-organisation-list>
        </main>
    </section>
</div>
</article>
</template>

<script>
import SubscriptionChangesList from '@/components/SubscriptionChangesList';
import RecentOrganisationList from '@/components/RecentOrganisationList';
import {CONFIG_KEYCLOAK_ADMIN_URL} from "@/config";
export default {
  computed: {
    CONFIG_KEYCLOAK_ADMIN_URL() {
      return CONFIG_KEYCLOAK_ADMIN_URL
    }
  },
    components: {RecentOrganisationList, SubscriptionChangesList},
};
</script>
