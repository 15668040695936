import store from '@/store';
import {CONFIG_API_URL} from "@/config";

const apiBaseUrl = CONFIG_API_URL;

class ApiError extends Error {
    name = '';

    constructor(message, response) {
        super(message);
        this.response = response;
    }
}

class AuthenticationError extends ApiError {
}

class UnauthorizedError extends ApiError {
}

async function fetchData(url, method, body) {
    await store.dispatch('auth/refreshToken');

    let authHeaders = store.state.auth.currentAuthHeaders;

    const headers = new Headers({
        'Content-Type': 'application/json',
        ...authHeaders
    });

    let init = {
        'method': method,
        'headers': headers,
        'cache': 'no-cache',
    };

    if (body)
        init['body'] = JSON.stringify(body);

    const response = await fetch(`${apiBaseUrl}${url}`, init);

    if (!response.ok && response.status == 401)
        throw new UnauthorizedError();
    else if (!response.ok)
        throw new ApiError('API returned error on ' + url, await response.json());

    return await response.json();
}

function searchParamsFromFilters(filters) {
    let searchParams = new URLSearchParams();
    for (let filter in filters) {
        if (Array.isArray(filters[filter])) {
            for (let value of filters[filter])
                searchParams.append(filter, value);
        } else if (filters[filter] != null) { // weak comparison on purpose, want to skip null/undefined
            searchParams.append(filter, filters[filter]);
        }
    }
    return searchParams;
}

export {ApiError, AuthenticationError, UnauthorizedError};

export default {
    createInvoice(invoice) {
        return fetchData('invoice/create', 'POST', invoice);
    },
    createInvoicePayment(invoiceId) {
        return fetchData(`invoice/${invoiceId}/payment`, 'POST');
    },
    createLabelPrice(labelPrice) {
        return fetchData('label-price/create', 'POST', labelPrice);
    },
    getAccounts(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`invoice/account/?${searchParams}`, 'GET');
    },
    getAccountTotals(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`invoice/totals/account/?${searchParams}`, 'GET');
    },
    getAccountTotalsCsv(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`invoice/totals/account/csv?${searchParams}`, 'GET');
    },
    getCourier(courierId) {
        return fetchData(`courier/${courierId}`, 'GET');
    },
    getGdprRequests() {
        return fetchData('gdpr/', 'GET');
    },
    getInvoice(invoiceId) {
        // TODO: refactor naming (getX/getXs is confusing)
        return fetchData(`invoice/${invoiceId}`, 'GET');
    },
    getInvoices(filters) {
        // TODO: refactor naming (getX/getXs is confusing)
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`invoice/?${searchParams}`, 'GET');
    },
    getInvoiceLabelCounts(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`invoice/label/count/?${searchParams}`, 'GET');
    },
    getInvoiceLabels(invoiceId=null, filters=null) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        if (invoiceId)
            return fetchData(`invoice/${invoiceId}/label/?${searchParams}`, 'GET');
        return fetchData(`invoice/label/?${searchParams}`, 'GET');
    },
    getInvoiceTotals() {
        return fetchData('invoice/totals/', 'GET');
    },
    getInvoicePdf(invoiceId) {
        // TODO: refactor naming (getX/getXs is confusing)
        return fetchData(`invoice/${invoiceId}/pdf`, 'GET');
    },
    getInvoicePdfs(filters) {
        // TODO: refactor naming (getX/getXs is confusing)
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`invoice/pdf?${searchParams}`, 'GET');
    },
    getLabelPrices(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`label-price/?${searchParams}`, 'GET');
    },
    getLastLabels(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`organisation/last_labels/?${searchParams}`, 'GET');
    },
    getOrganisation(organisationId) {
        // TODO: refactor naming (getX/getXs is confusing)
        return fetchData(`organisation/${organisationId}`, 'GET');
    },
    getOrganisations(filters) {
        // TODO: refactor naming (getX/getXs is confusing)
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`organisation/?${searchParams}`, 'GET');
    },
    getPlans() {
        return fetchData('plan/', 'GET');
    },
    getServices(serviceType, filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`service/${serviceType}/?${searchParams}`, 'GET');
    },
    getServiceMeta(serviceType) {
        return fetchData(`service/${serviceType}/meta/`, 'GET');
    },
    getSubscriptionChanges() {
        return fetchData('organisation/subscription_changes/', 'GET');
    },
    getUsers(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`organisation/users/?${searchParams}`, 'GET');
    },
    searchServiceItems(filters) {
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`service-item/?${searchParams}`, 'GET');
    },
    sendInvoiceMail(id, data) {
        return fetchData(`invoice/${id}/mail`, 'POST', data);
    },
    updateInvoice(id, updates) {
        // TODO: refactor naming (getX/getXs is confusing)
        return fetchData(`invoice/${id}`, 'PATCH', updates);
    },
    updateInvoices(ids, updates) {
        // TODO: refactor naming (getX/getXs is confusing)
        return fetchData('invoice/', 'PATCH', {
            ...updates,
            ids: ids,
        });
    },
    updateLabel(id, updates) {
        return fetchData(`label/${id}`, 'PATCH', updates);
    },
    updateLabelPrice(id, updates) {
        return fetchData(`label-price/${id}`, 'PATCH', updates);
    },
    updateOrganisation(id, updates) {
        return fetchData(`organisation/${id}`, 'PATCH', updates);
    },
    updateTicketPhase(updates) {
        return fetchData(`ticket/phase`, 'POST', updates);
    },
    updateTicketLastOpened(data) {
        return fetchData(`ticket/opened`, 'POST', data);
    },
    createLabelDump(data) {
        return fetchData('label-dump', 'POST', data);
    },
    getTickets() {
        return fetchData(`ticket`, 'GET');
    },
    getSingleTicket(ticketId) {
        return fetchData(`ticket/${ticketId}`, 'GET');
    },
    getAttachmentByTicketId(ticketId) {
        return fetchData(`ticket/${ticketId}/attachments`, 'GET');
    },
    createTicket(data) {
        return fetchData('ticket/create', 'POST', data);
    },
    replyTicket(ticketId, data) {
        return fetchData(`ticket/${ticketId}/reply`, 'POST', data);
    },
    updateTicket(ticketId, data) {
        return fetchData(`ticket/${ticketId}`, 'PATCH', data);
    },
    migrateUsers() {
        return fetchData(`migrate-users`, 'POST');
    },
    impersonateUser(organisation_id) {
        return fetchData(`organisation/${organisation_id}/impersonate`, 'POST');
    },
    resetLabelPrices(filters) {
        if (!filters)
            filters = {};
        const searchParams = searchParamsFromFilters(filters);
        return fetchData(`label/reset_price?${searchParams}`, 'PUT');
    },
}
