import moment from 'moment';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import convert from 'convert-units';

export function getMoment(value) {
  if (moment.isMoment(value)) return value;
  return moment(value);
}

export function pluralize(amount, single, plural) {
  if (amount === 1) return single;
  return plural;
}

export function download(filename, text, mimetype = 'text/plain', encoding = 'charset=utf-8') {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:' + mimetype + ';' + encoding + ',' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function isEqualSet(a, b) {
  return a.size === b.size && [...a].every(value => b.has(value));
}

export function debounce(fn, delay = 300) {
  let debounceTimeoutId;

  function debounced() {
    if (debounceTimeoutId) clearTimeout(debounceTimeoutId);

    debounceTimeoutId = setTimeout(() => fn.apply(this, arguments), delay);
  }
  return debounced;
}
export function cn(...classes) {
  return twMerge(clsx(classes));
}

export const toHumanReadableWeight = grams => {
  const weight = convert(grams).from('g').toBest();

  return weight.val.toFixed(2) + weight.unit;
};
